//*******************************************************************************
// Title Component
//
//*******************************************************************************

.title {
	// outline: 1px solid black;
	margin-bottom: 0;
	padding-bottom: 12vw;

	@include breakpoint(min) {
		margin-bottom: 0;
		padding-bottom: 48px;
	}
	@include breakpoint(mid) {
		margin-bottom: 0;
		padding-bottom: 4vw;
	}
}

.title__main {
	// outline: 1px solid red;
	display: block;
	margin-bottom: 0 !important;
}

.title__preheadline {
	// outline: 1px solid blue;
	display: block;
	margin-top: 0 !important;
	margin-bottom: 6vw;
	@include font-family(regular);
	@include headline(30);

	@include breakpoint(min) {
		margin-bottom: 24px;
	}

	@include breakpoint(mid) {
		margin-bottom: 2vw;
	}

	&--bigger {
		font-size: 3vw;
	}
}

.title__subheadline {
	display: block;
	margin-bottom: 0 !important;
	@include font-family(headlight);
	@include headline(26);
}
