.title__preheadline {
	@include font-family(regular);
}

.header-hero--large {
	border-bottom: none!important;
}


// overwrites from base package - TODO: find a better solution, but this works for now
body {
	@include font-family(sansregular);
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
h4,
h5,
h6,
strong,
.strong {
  @include font-family(headblack);
}

.text-headlight {
  @include font-family(headlight);
}
.text-headblack {
  @include font-family(headblack);
}
.text-sansbold {
  @include font-family(sansbold);
}
.text-sansregular {
  @include font-family(sansregular);
}
