//*******************************************************************************
// Download List
//*******************************************************************************
.section--small.db-downloads {
	overflow: visible;
}
.downloadlist {
	.section--small & {
		margin-left: -5vw;
		margin-right: -5vw;

		@include breakpoint(mid) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.downloadlist__item:last-child .downloadlist__link {
		border-bottom: 1px solid transparent;
	}
}

.downloadlist__title {
	padding-left: 5vw;
	padding-right: 5vw;

	@include breakpoint(mid) {
		padding-left: 0;
		padding-right: 0;
	}
}

.downloadlist__list {
	@include breakpoint(mid) {
		margin-left: -1.5vw;
		margin-right: -1.5vw;
	}

	@include breakpoint(max) {
		margin-left: -1vw;
		margin-right: -1vw;
	}
}

.downloadlist__item {
	position: relative;
	list-style: none;
	margin-bottom: 0;

	@include breakpoint($min: 800px) {
		padding: 0;
	}
}

.downloadlist__link,
.downloadlist__link-meta {
	display: flex;
	flex-wrap: wrap;
}

.downloadlist__link {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;

	background: color(coolgray100);
	border-bottom: 1px solid color(coolgray200);
	border-radius: $border-radius;
	color: color(black);
	padding: 0.75em calc(6vw + 30px) 0.75em 5vw;
	position: relative;
	line-height: 1.4;
	text-decoration: none;
	transition: color ease 0.2s, background ease 0.2s;
	min-height: 60px;

	&--icon {
		padding-left: calc(6vw + 30px);
	}

	// &::before {
	// 	content: "";
	// 	background-size: 100%;
	// 	background-position: center;
	// 	background-repeat: no-repeat;
	// 	width: 30px;
	// 	height: 100%;
	// 	left: 3vw;
	// 	top: 50%;
	// 	transform: translateY(-50%);
	// 	position: absolute;
	// 	@include background-svg(
	// 		'<svg xmlns="http://www.w3.org/2000/svg" width="34" height="47"><path d="M23.22 0c1 0 1.96.42 2.7 1.1l6.96 6.87A3.7 3.7 0 0134 10.65v32.57c0 2.1-1.7 3.78-3.83 3.78H3.83A3.79 3.79 0 010 43.22V3.78C0 1.68 1.7 0 3.83 0h19.39zm-2.34 3H3.41c-.22.02-.4.2-.42.42v40.16c0 .21.21.42.42.42h27.16c.2 0 .42-.2.42-.42V13h-8.44c-.95 0-1.68-.73-1.68-1.67V3zM24 4v6h6l-6-6zM7 35.5c0 .84.73 1.5 1.67 1.5h16.66c.94 0 1.67-.66 1.67-1.5s-.73-1.5-1.67-1.5H8.67C7.73 34 7 34.66 7 35.5zm0-7c0 .84.73 1.5 1.67 1.5h16.66c.94 0 1.67-.66 1.67-1.5s-.73-1.5-1.67-1.5H8.67C7.73 27 7 27.66 7 28.5zm0-7c0 .84.73 1.5 1.67 1.5h16.66c.94 0 1.67-.66 1.67-1.5s-.73-1.5-1.67-1.5H8.67C7.73 20 7 20.66 7 21.5z" fill="#000" fill-rule="nonzero"/></svg>'
	// 	);
	// }

	@include breakpoint(mid) {
		flex-direction: row;
		padding-left: 2.5vw;
		flex-wrap: nowrap;
		align-items: center;

		// &::before {
		// 	display: none;
		// }
	}


	.downloadlist:hover & {
		border-bottom: 1px solid transparent;
	}

	&:focus,
	&:hover {
		background: color(coolgray200);
		color: color(black);
		text-decoration: none;
		border-radius: $border-radius;
		box-shadow: $box-shadow--1;
		border-bottom: 1px solid transparent;
	}

	&:active {
		color: color(red600);
	}

	// @include breakpoint($min: 800px) {
	// 	flex-wrap: nowrap;
	// 	padding: 0;
	// }
}

.downloadlist__link-meta {
	justify-content: space-between;
	flex-wrap: nowrap;
	flex-shrink: 0;
}

.downloadlist__link-name {
	$textfontsizemin: 14;
	$textlineheightmin: 20;
	$textfontsizemax: 18;
	$textlineheightmax: 24;

	display: inline-block;
	flex: 1 0 100%;
	padding-right: 40px;
	margin-bottom: 4px;
	hyphens: auto;
	word-break: break-word;

	@include breakpoint(min) {
		font-size: $textfontsizemin + px;
		line-height: $textlineheightmin + px;
	}

	@include breakpoint(mid) {
		font-size: calc(
			#{$textfontsizemin}px + (#{$textfontsizemax} - #{$textfontsizemin}) *
				((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint}))
		);
		line-height: 1.5;
		flex: 1 1 auto;
		// margin: 1.4vw 1.5vw 1.3vw 1.5vw;
		// padding: 0;
	}

	@include breakpoint(max) {
		margin: 1vw;
	}
}

.downloadlist__link-filetype,
.downloadlist__link-filesize,
.downloadlist__link-date,
.downloadlist__link-icon {
	font-size: calc(
		#{$textsmallfontsizemin}px +
			(#{$textsmallfontsizemax} - #{$textsmallfontsizemin}) *
			((100vw - #{$textminbreakpoint}px) / (1600 - #{$textminbreakpoint}))
	);
	line-height: calc(
		#{$textsmalllineheightmin}px +
			(#{$textsmalllineheightmax} - #{$textsmalllineheightmin}) *
			((100vw - #{$textminbreakpoint}px) / (1600 - #{$textminbreakpoint}))
	);

	display: inline-block;
	margin-bottom: 0;
	color: color(coolgray500);
	flex: 0 0 auto;

	.downloadlist__link:focus &,
	.downloadlist__link:hover & {
		color: color(black);
	}

	.downloadlist__link:active & {
		color: color(red600);
	}

	@include breakpoint(mid) {
		$textfontsizemin: 14;
		$textlineheightmin: 20;
		$textfontsizemax: 18;
		$textlineheightmax: 24;

		font-size: calc(
			#{$textfontsizemin}px + (#{$textfontsizemax} - #{$textfontsizemin}) *
				((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint}))
		);
		line-height: 1;

		// margin: 1.4vw 1.5vw 1.3vw 1.5vw;
		// color: color(dark);
	}

	// @include breakpoint(max) {
	// 	margin: 1vw;
	// }
}

//
// Filetype
//
.downloadlist__link-filetype {
	.section & {
		// @include breakpoint(mid) {
		// 	flex-basis: 8%;
		// 	max-width: 100px;
		// }
	}

	.section--small & {
		// @include breakpoint(mid) {
		// 	flex-basis: 10%;
		// 	max-width: 140px;
		// }
	}
}

//
// Filesize
//
.downloadlist__link-filesize {
	margin-left: 1.5vw;
	margin-right: 1.5vw;

	.section & {
		// @include breakpoint(mid) {
		// 	flex-basis: 10%;
		// 	max-width: 80px;
		// }
	}

	.section--small & {
		// @include breakpoint(mid) {
		// 	flex-basis: 13%;
		// 	max-width: 130px;
		// }

		// @include breakpoint(max) {
		// 	max-width: 90px;
		// }
	}

}

//
// Date
//
.downloadlist__link-date {
	flex-shrink: 1;
	overflow: hidden;
	text-overflow: ellipsis;

	// .section & {
	// 	@include breakpoint(mid) {
	// 		flex-basis: 16.7%;
	// 		max-width: 100px;
	// 	}
	// }

	// .section--small & {
	// 	@include breakpoint(mid) {
	// 		flex-basis: 16.7%;
	// 		max-width: 160px;
	// 	}

	// 	@include breakpoint(max) {
	// 		max-width: 110px;
	// 	}
	// }
}

.downloadlist__link-image,
.downloadlist__link-icon {
	display: block;
	position: absolute;
	top: 50%;
	padding: 0;
	transform: translate(0, -50%);
}

.downloadlist__link-image {
	left: 2.5vw;
	transition: background ease 0.2s;
	width: 3em;
	height: 100%;

	> svg {
		width: 100%;
		height: 100%;
	}

	path {
		fill: color(red500);
		transition: fill ease 0.2s;

		.downloadlist__link:hover & {
			fill: color(black);
		}
	}

	@include breakpoint(mid) {
		background: color(coolgray100);
		border-radius: $border-radius;
		left: -0.5em;
		padding: 0 0.8em;
		transform: translate(-100%, -50%);
		width: 4em;

		.downloadlist__link:hover &,
		.downloadlist__link:focus & {
			background: color(coolgray200);
			text-decoration: none;
			border-radius: $border-radius;
			box-shadow: $box-shadow--1;
			border-bottom: 1px solid transparent;
		}
	}
}

.downloadlist__link-icon {
	right: 5vw;
	width: 20px;
	height: 20px;
	background: right top no-repeat transparent;
	background-size: 100%;
	@include background-svg(
		'<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M4 21c0-.563.438-1 1-1 .563 0 1 .437 1 1v6c0 .562.438 1 1 1h18c.562 0 1-.438 1-1v-6c0-.563.437-1 1-1 .562 0 1 .437 1 1v6a3 3 0 01-3 3H7a3 3 0 01-3-3v-6zm5.281-5.313a.97.97 0 01-.28-.687c0-.5.343-1 1-1 .25 0 .5.094.718.281L15 18.593V3c0-.563.438-1 1-1 .563 0 1 .438 1 1v15.593l4.281-4.312A1.1 1.1 0 0122 14c.594 0 1 .437 1 1 0 .25-.094.5-.281.719l-6 6a1.1 1.1 0 01-.719.28.97.97 0 01-.688-.28l-6.03-6.032z" fill="#282D37" fill-rule="nonzero"/></svg>'
	);

	.downloadlist__link:focus &,
	.downloadlist__link:hover & {
		@include background-svg(
			'<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M4 21c0-.563.438-1 1-1 .563 0 1 .437 1 1v6c0 .562.438 1 1 1h18c.562 0 1-.438 1-1v-6c0-.563.437-1 1-1 .562 0 1 .437 1 1v6a3 3 0 01-3 3H7a3 3 0 01-3-3v-6zm5.281-5.313a.97.97 0 01-.28-.687c0-.5.343-1 1-1 .25 0 .5.094.718.281L15 18.593V3c0-.563.438-1 1-1 .563 0 1 .438 1 1v15.593l4.281-4.312A1.1 1.1 0 0122 14c.594 0 1 .437 1 1 0 .25-.094.5-.281.719l-6 6a1.1 1.1 0 01-.719.28.97.97 0 01-.688-.28l-6.03-6.032z" fill="#000000" fill-rule="nonzero"/></svg>'
		);
	}

	.downloadlist__link:active & {
		@include background-svg(
			'<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M4 21c0-.563.438-1 1-1 .563 0 1 .437 1 1v6c0 .562.438 1 1 1h18c.562 0 1-.438 1-1v-6c0-.563.437-1 1-1 .562 0 1 .437 1 1v6a3 3 0 01-3 3H7a3 3 0 01-3-3v-6zm5.281-5.313a.97.97 0 01-.28-.687c0-.5.343-1 1-1 .25 0 .5.094.718.281L15 18.593V3c0-.563.438-1 1-1 .563 0 1 .438 1 1v15.593l4.281-4.312A1.1 1.1 0 0122 14c.594 0 1 .437 1 1 0 .25-.094.5-.281.719l-6 6a1.1 1.1 0 01-.719.28.97.97 0 01-.688-.28l-6.03-6.032z" fill="#C50014" fill-rule="nonzero"/></svg>'
		);
	}

	@include breakpoint(mid) {
		right: 3vw;
	}

	// @include breakpoint($min: 800px) {
	// 	position: relative;
	// 	top: auto;
	// 	right: auto;
	// 	margin: 1.5vw;
	// 	// flex-basis: 5.5%;
	// 	// min-width: 40px;
	// 	// max-width: 80px;
	// 	background-position: center center;
	// 	transform: none;
	// }

	// @include breakpoint($min: 1600px) {
	// 	// flex-basis: 59px;
	// 	flex-basis: 32px;
	// 	width: 32px;
	// 	height: 32px;
	// 	margin: 0.75vw 1vw 0.75vw 0.75vw;
	// }
}

.downloadlist:focus-within .downloadlist__link {
	border-bottom: 1px solid transparent;
}

//
// Inside Section Small
//
// .section--small {
// 	.downloadlist__link-filetype,
// 	.downloadlist__link-filesize,
// 	.downloadlist__link-date,
// 	.downloadlist__link-icon {
// 		margin-left: 0;
// 	}
// }

//*******************************************************************************
// DOWNLOADLIST CSS ONLY
//*******************************************************************************
ul.downloads {
	list-style: none;
	margin-bottom: 2vw;
	padding: 0;

	> li {
		margin: 0 0 0.5em 0;
		padding: 0;
		@include textcompactsize;

		&.additional-entry {
			display: none;
		}

		> a {

			.title {
				width: 100%;
				@include font-family(sansbold);

				@include breakpoint(mid) {
					width: 40%;
				}
			}

			// .fileinfo {
			// 	display: flex;
			// 	justify-content: space-between;
			// 	width: 50%;
			// 	color: color(black);
			// 	font-size: 10px;

			// 	@include breakpoint(mid) {
			// 		width: 40%;
			// 		@include textcompactsize;
			// 	}

			// 	.filesize {
			// 		margin-left: 1em;
			// 	}

			// 	.filedate {
			// 		margin-left: 1em;
			// 	}
			// }

			.download {
				width: 1em;
				text-align: right;
				height: 100%;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100%;
				position: absolute;
				right: 2.5vw;
				top: 50%;
				transform: translateY(-50%);
				@include background-svg(
					'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 24"><path d="M0 15.9c0-.506.365-.9.833-.9.47 0 .834.394.834.9v5.4c0 .506.364.9.833.9h15c.469 0 .833-.394.833-.9v-5.4c0-.506.365-.9.834-.9.468 0 .833.394.833.9v5.4c0 1.49-1.12 2.7-2.5 2.7h-15C1.12 24 0 22.79 0 21.3v-5.4zm4.241-4.266A.82.82 0 014 11.05c0-.425.295-.85.857-.85.214 0 .429.08.616.24l3.67 3.665V.85c0-.478.375-.85.857-.85s.857.372.857.85v13.255l3.67-3.666a.948.948 0 01.616-.239c.509 0 .857.372.857.85 0 .212-.08.425-.241.611l-5.143 5.1A.948.948 0 0110 17a.835.835 0 01-.59-.239l-5.169-5.127z" fill="#{color(red500)}" fill-rule="nonzero"/></svg>'
				);

				@include breakpoint(mid) {
					position: static;
					transform: none;
					height: 1.5em;
				}
			}
		}
	}
}

ul.downloads + label {
	display: block;
	cursor: pointer;
	text-align: center;
}

.plus-minus-toggle {
	.focus-visible {
		outline: none;
		text-decoration: none;
		box-shadow: 0 0 0 2px color(red500);
	}
}

input[type="checkbox"].show-additional-entries {
	display: none;

	&:checked + ul.downloads li.additional-entry {
		display: block;
	}

	& + ul.downloads + label .plus-minus-toggle {
		span:first-child {
			display: inline-block;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				width: 1.75em;
				height: 1.75em;
				left: -0.5em;
				top: 50%;
				transform: translate(-100%, -57.5%);
				background-repeat: no-repeat;
				background-size: 100%;
				background-position: center;
				@include background-svg(
					'<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M16 6c.56 0 1 .44 1 1v8h8c.56 0 1 .44 1 1s-.44 1-1 1h-8v8c0 .56-.44 1-1 1s-1-.44-1-1v-8H7c-.56 0-1-.44-1-1s.44-1 1-1h8V7c0-.56.44-1 1-1z" fill="#EC0016" fill-rule="evenodd"/></svg>'
				);
			}
		}

		span:last-child {
			display: none;
		}
	}

	&:checked + ul.downloads + label .plus-minus-toggle {
		span:first-child {
			display: none;
		}

		span:last-child {
			display: inline-block;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				width: 1em;
				height: 2em;
				left: -0.75em;
				top: 50%;
				transform: translate(-100%, -55%);
				background-repeat: no-repeat;
				background-size: 100%;
				background-position: center;
				@include background-svg(
					'<svg width="20" height="2" xmlns="http://www.w3.org/2000/svg"><path d="M1 2h18c.56 0 1-.5 1-1 0-.56-.44-1-1-1H1C.44 0 0 .5 0 1c0 .56.44 1 1 1z" fill="#EC0016" fill-rule="evenodd"/></svg>'
				);
			}
		}
	}

	& + ul.downloads + label .plus-minus-toggle,
	&:checked + ul.downloads + label .plus-minus-toggle {
		&:hover {
			color: color(red500);
		}
	}
}
