//*******************************************************************************
// MENU LARGE
//*******************************************************************************

// TODO: Refactor this whole file as it is very badly readable. E.g. when using BEM one should not overly nest selectors. Also full class names improve readability.
//
// Main Manü
//
.overflow-hidden {
  overflow: hidden;
}

.header-hero--large {
  .menu {
    $self: &;
    display: none;
    flex-direction: column;
    margin-bottom: 0;
    width: 100%;

    font-size: 19px;
    line-height: 1.7;
    margin: 0;
    padding: 0;
    z-index: 100;

    @include breakpoint($breakpoint-mid) {
      display: flex;
      width: auto;
      gap: 0 12px;
      position: static;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      font-size: inherit;
      line-height: inherit;
    }

    &.menu--open {
      display: flex;
      position: absolute;
      top: 80px;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      margin: 0;
      padding: 12vw 5vw 60vw;
      overflow: scroll;
      background-color: color(bright);
      z-index: 100;

      @include breakpoint($breakpoint-mid) {
        padding: 0;

        // apply .menu styles so the menu does not break on resize
        display: flex;
        width: auto;
        position: static;
        flex-direction: row;
        align-items: center;
        font-size: inherit;
        line-height: inherit;
        background-color: transparent;
        height: auto;
      }
    }

    .menu__item {
      position: static;
      list-style-type: none;
      margin: 0 0 40px 0;
      font-size: inherit;
      line-height: inherit;
      flex-shrink: 0;

      &--mobile {
        margin: 0 0 10px 0;
      }

      @include breakpoint($breakpoint-mid) {
        min-height: 80px;
        display: flex;
        align-items: center;
        margin: 0;

        &--mobile {
          display: none;
          margin: 0;
        }

        &:last-child {
          // this is a workaround, as I dont't wish to restyle the whole thing from Mannheim-Karlsruhe.
          // This makes it look as if padding of .menu applies to items. But Items have a padding to the right which would make the gap appear too big.
          // applied to last child so that if one places something as additionalItem fusion prop, style will not be applied
          margin-right: -20px;
        }
      }
      &--is-open {
        min-height: auto;
        .menu__link {
          &::before {
            content: "";
            position: absolute;
            height: 3px;
            right: 1vw;
            bottom: 1vw;
            left: 1vw;
            background: color(red500);
            display: none;
          }
        }

        .submenu {
          display: flex;
        }
      }

      &.header-hero__topbar-item {
        min-height: 30px;
      }
    }

    .menu__button {
      &.btn {
        @include font-family(sansbold);
        background-color: color(red500);
        border-color: color(red500);
        padding: 6px 12px;
        height: auto;
        min-height: auto;
        font-size: inherit;
        line-height: inherit;

        @include breakpoint(lg) {
          padding: 6px 36px;
        }

        @include breakpoint(xl) {
          padding: 9px 36px;
        }

        &:hover,
        &:focus {
          background-color: color(red600);
          border-color: color(red600);
        }
      }
    }

    .menu__link {
      @include font-family(sansbold);
      padding: 2vw 0;
      position: relative;
      display: block;
      line-height: 1;
      border-bottom: none !important;

      // mobile only view
      @media (max-width: ($breakpoint-mid - 1px)) {
        display: block;
        // align-items: center;

				:not(.menu__item--is-open) & {
					&.focus-visible {
						outline: 2px solid color(red500);
						outline-offset: 2px;
						box-shadow: none;

						& > .menu__link--text {
							background-color: transparent;
						}
					}
				}


        &:focus,
        &:hover,
        &:active {
          border-bottom-color: transparent;
          text-decoration: none;
          color: color(dark);

          .menu__link--text {
            padding: 3.75vw 35px 3.75vw 2.5vw;
            margin-left: -2.5vw;
            margin-right: -2.5vw;
            border-radius: 6px;

            // background-color: color(coolgray100);
          }
        }
      }

      @include breakpoint(mid) {
        padding: 2vw 1vw;
      }

      &:not(.header-hero__topbar-link) {
        color: color(black);
      }

      &:hover,
      &:focus,
      &.is-active,
      &.active,
      #{$self}__item--is-active > & #{$self}__item--is-open > & {
        color: color(red500);
        &::before {
          @include breakpoint(mid) {
            display: block;
          }
        }
      }

      // secondary variant used for toplink items in mobile-menu
      &--secondary {
        @media (max-width: ($breakpoint-mid - 1px)) {
          min-height: 0;
          padding: 0;
          margin-bottom: 5vw;
        }
      }

      .menu__item--has-submenu {
        display: inline-flex;
        align-items: center;

        &::after {
          content: "";
          display: block;
          margin-left: 8px;
          width: 11px;
          height: 6px;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.99.772l.057.066 4.25 4.893a.801.801 0 001.2 0L10.744.847l.072-.081A.483.483 0 0010.9.494.507.507 0 0010.381 0H1.42A.507.507 0 00.9.494c0 .103.034.2.09.278z' fill='%23007E91' fill-rule='nonzero'/%3E%3C/svg%3E");
        }
      }

      &.btn {
        margin-top: 2vw;
        margin-left: 16px;

        .header-hero__navigation--bigger {
          @include breakpoint(lg) {
            margin-top: 0;
          }
        }
      }

      .menu__link--text {
        padding: 0;
      }
    }

    .menu__item--has-submenu > .menu__link {
      @include breakpoint(mid) {
        padding-right: 34px;
      }

      .menu__link--text::after {
        @include background-svg(
          '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18 8c.563 0 1 .438 1 1 0 .25-.125.5-.313.688l-5.968 6.03A1.044 1.044 0 0112 16c-.25 0-.531-.094-.719-.281L5.312 9.688C5.125 9.5 5 9.25 5 9c0-.563.438-1 1-1 .281 0 .5.094.719.281L12 13.562l5.281-5.28A.99.99 0 0118 8z" fill="#282D37" fill-rule="evenodd"/></svg>'
        );

        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;

        @include breakpoint(mid) {
          top: 50%;
        }
      }

      .menu__link--text:focus::after,
      .menu__link--text:hover::after {
        @include background-svg(
          '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18 8c.563 0 1 .438 1 1 0 .25-.125.5-.313.688l-5.968 6.03A1.044 1.044 0 0112 16c-.25 0-.531-.094-.719-.281L5.312 9.688C5.125 9.5 5 9.25 5 9c0-.563.438-1 1-1 .281 0 .5.094.719.281L12 13.562l5.281-5.28A.99.99 0 0118 8z" fill="#ec0016" fill-rule="evenodd"/></svg>'
        );
      }

      .header-hero__navigation--bigger {
        padding-right: 16px;

        @include breakpoint(mid) {
          &::after {
            top: 22px;
          }
        }

        @include breakpoint(lg) {
          padding-right: 44px;

          &::after {
            top: 50%;
          }
        }
      }
    }

    .menu__item--has-submenu.menu__item--is-open {
      > .menu__link .menu__link--text::after {
        // outline: 1px solid red;
        @include background-svg(
          '<svg width="14" height="8" viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg"><path d="M13 8c.563 0 1-.438 1-1 0-.25-.125-.5-.313-.688L7.719.282A1.044 1.044 0 0 0 7 0c-.25 0-.531.094-.719.281L.312 6.312C.125 6.5 0 6.75 0 7c0 .563.438 1 1 1 .281 0 .5-.094.719-.281L7 2.438l5.281 5.28A.99.99 0 0 0 13 8Z" fill="#ec0016" fill-rule="evenodd"/></svg>'
        );
      }
    }

    .menu__lang-menu {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      .menu__item {
        display: flex;
        align-items: center;
        margin-right: 2vw;
        padding: 0;

        &--globe > img {
          width: 4vw;
        }
      }

      .menu__link {
        margin-bottom: 0;
        padding: 10px 0;
        text-transform: uppercase;
        font-size: 14px;
        @include breakpoint(mid) {
          font-size: inherit;
        }
      }
    }

    .header-hero__navigation--bigger {
      @include breakpoint(mid) {
        display: none;
        font-size: 19px;
        line-height: 1.7;
      }

      @include breakpoint(lg) {
        display: flex;
        align-items: center;
        font-size: inherit;
        line-height: inherit;
      }
    }
  }
}

//
// Submenu
//
.header-hero--large {
  .submenu {
    $self: &;
    display: none;
    background: color(white);
    flex-direction: column;
    padding-bottom: 5vw;
    border-radius: $border-radius;
    z-index: 200;

    @include breakpoint(mid) {
      border-top: 1px solid color(coolgray200);
      position: absolute;
      flex-direction: column;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: none;
      width: 100%;
      padding: 0;
      margin-top: 0;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 8px 8px 0 rgba(0, 0, 0, 0.1), 0 8px 16px 0 rgba(0, 0, 0, 0.15);
      transform: translateY(100%);
    }

    @include breakpoint(md) {
      flex-direction: row;
    }

    // white background and shadow element for bottom shadow
    &::before,
    &::after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      display: none;

      @include breakpoint(mid) {
        display: block;
      }
    }

    &::before {
      height: 40px;
      bottom: 0;
      background: none;

      @include breakpoint(mid) {
        display: block;
        width: 100%;
      }
    }

    &::after {
      top: 0;
      bottom: 0;
      background: color(white);
    }

    .menu__item.is-open & {
      display: flex;
    }

    .submenu__main {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;

      @include breakpoint(mid) {
        padding-bottom: 3.75vw;
        flex-wrap: wrap;
        flex-direction: row;
      }

      @include breakpoint(md) {
        flex: 0 0 75%;
      }
    }

    .submenu__item {
      font-size: inherit;
      line-height: inherit;
      list-style-type: none;
      display: block;
      margin-left: 0;
      margin-bottom: 10px;
      position: relative;

      &:last-child a {
        margin-bottom: 0;
      }
    }

    .submenu__col {
      @include breakpoint(sl) {
        margin-left: 3vw;

        &--half {
          flex: 0 0 42.5vw;
        }

        &--full {
          flex: 0 0 90vw;
        }

        &--max {
          flex: 0 0 90vw;
        }
      }

      @include breakpoint(md) {
        margin-left: 3vw;

        &--half {
          flex: 0 0 25vw;
        }

        &--full {
          flex: 0 0 55vw;
        }

        &--max {
          flex: 0 0 60vw;
        }
      }
    }

    .submenu__aside {
      position: relative;
      z-index: 1;
      margin-top: 5vw;

      .submenu__link-wrapper {
        display: block;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;
        height: 2px;
        background-color: color(coolgray100);
      }

      @include breakpoint(sl) {
        padding: 0 3vw 3.75vw;
        margin-top: 0;
        flex: 0 0 100%;
      }

      @include breakpoint(md) {
        margin-top: 0;
        flex: 0 0 25%;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 60%;
          background-color: color(coolgray100);
        }

        .msie & {
          flex-basis: auto;
          width: 25%;
        }
      }

      .submenu__link {
        background: color(white);

        @media (max-width: ($breakpoint-sl - 1px)) {
          padding-left: 5vw;
        }

        &:hover,
        &:focus {
          color: color(black);
          background: color(coolgray200);
        }
      }
    }

    //wraps different links
    .submenu__category {
      @include breakpoint(mid) {
        margin-bottom: 5vw;
        margin-top: 2vw;
        margin-bottom: 0;
      }
    }

    .submenu__category-title {
      @include font-family(regular);
      color: color(warmgray600);
      font-size: 12px;
      line-height: 1;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      display: block;
      min-height: 22px;
    }

    .submenu__link-wrapper {
      display: flex;
      flex-direction: column;

      column-gap: 5vw;

      img {
        @include breakpoint(sl) {
          margin-top: 1.25vw;
          margin-bottom: 1.25vw;
          padding: 14px 0;
        }
      }

      p {
        @include font-family(sansbold);
        line-height: 1.38;

        @include breakpoint(sl) {
          margin-top: 1.25vw;
          margin-left: -12px;
          margin-right: -12px;
          margin-bottom: 1.25vw;
          padding: 14px 12px;
          //background: color(transparent);
        }
      }
    }

    .submenu__main--without-aside {
      @include breakpoint(md) {
        flex: 0 0 100%;
      }

      .submenu__link-wrapper {
        padding-right: 0;
      }

      .submenu__col {
        &--max {
          flex: 0 0 90vw;
        }

        @include breakpoint(sl) {
          margin-left: 5vw;

          &--max {
            flex: 0 0 90vw;
          }
        }

        @include breakpoint(md) {
          margin-left: 5vw;

          &--max {
            flex: 0 0 90vw;
          }
        }
      }
    }

    //map inside
    .interactive-map {
      @include breakpoint(sl) {
        margin-top: 2vw;
      }
    }

    // contains title and options icon and description-text
    .submenu__link {
      @include font-family(sansregular);
      padding: 3.75vw 5vw 3.75vw 2.5vw;
      background: transparent;
      font-size: 16px;
      display: flex;
      margin-top: 2.5vw;
      margin-left: -2.5vw;
      margin-right: -2.5vw;
      line-height: 1.4;
      border-radius: $border-radius;
      border-bottom: 3px solid transparent;
      color: color(black);
      // align from start, in case no subtitle is set
      align-items: center;
      flex: 1;

      @media (max-width: ($breakpoint-sl - 1px)) {
        background: color(coolgray100);
      }

      @include breakpoint(sl) {
        margin-top: 1.25vw;
        margin-left: -12px;
        margin-right: 12px;
        padding: 14px 12px;
      }

      @include breakpoint(mid) {
        padding: 10px;
      }

      &:hover,
      &:focus {
        color: color(black);
        background: color(coolgray200);
        border-bottom: 3px solid transparent;
      }

      &.is-active {
        background: color(coolgray200);
        border-bottom: 3px solid transparent;
      }

      & + & {
        margin-top: 10px;
      }
    }

    .submenu__link-icon {
      flex: 0 0 10vw;
      width: 10vw;
      height: 10vw;
      margin-right: 5vw;
      display: block;

      @include breakpoint(sl) {
        flex: 0 0 40px;
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }

      @include breakpoint(md) {
        flex: 0 0 60px;
        width: 60px;
        height: 60px;
      }
    }

    // &__link-text {}

    .submenu__link-description {
      @include font-family(regular);
      display: block;
      color: color(warmgray600);
      margin-top: 4px;
      font-size: 13px;
      line-height: 1.38;
    }

    //map component inside menu
    .map {
      margin-top: 30px;

      @media (max-width: ($breakpoint-md - 1px)) {
        display: none;
      }
    }

    .submenu__item {
      flex-basis: calc(100% / 3);
    }
  }
}

//
// Toogle open / close States
//
.header-hero--large {
  .menu__item--is-open {
    .menu__link {
      color: color(black);
    }

    .submenu {
      display: flex;
    }

    // mobile only styles for open submenu
    @media (max-width: ($breakpoint-mid - 1px)) {
      .submenu {
        margin-left: -2.5vw;
        margin-right: -2.5vw;
        padding-top: 20px;

        background-color: color(coolgray100);

        .submenu__link {
          margin-top: 1vw;
          margin-left: 12px;
          margin-right: 12px;
        }

        .submenu__aside,
        .submenu__category-title {
          padding: 3.75vw 2.5vw 3.75vw 2.5vw;
        }
      }

      .menu__link .menu__link--text {
        padding: 3.75vw 35px 3.75vw 2.5vw;
        margin-left: -2.5vw;
        margin-right: -2.5vw;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        background-color: color(coolgray100);
      }
    }
  }
}

//
// Hamburger Menü Icon
//
.header-hero--large {
  .showmenu__toggle {
    border: none;
    background: none;
    cursor: pointer;
    font-family: inherit;
    position: relative;
    display: block;
    margin: 16px 0;
    padding: 0;
    width: 24px;
    height: 20px;
    z-index: 200;

		&.focus-visible {
			outline: 2px solid color(red500);
			outline-offset: 2px;
		}

    @include breakpoint($breakpoint-mid) {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }

    .header-hero__navigation--bigger & {
      @include breakpoint(mid) {
        display: block;
      }

      @include breakpoint(lg) {
        display: none;
      }
    }
  }
}

//
//
//
.header-hero--large {
  .showmenu__icon {
    top: 0px;
    background: #000;

    &,
    &::before,
    &::after {
      display: block;
      position: absolute;
      width: 24px;
      height: 3px;
      content: "";
      cursor: pointer;
      background-color: color(black);
    }

    &::before {
      top: 8px;
    }

    &::after {
      top: 16px;
      width: 16px;
    }
  }

  .showmenu__input {
    display: none;

    // &:checked+.menu {}
  }
}

.header-hero--slim {
  margin-bottom: 0;

  .header-hero__main {
    padding-top: 0;
    padding-bottom: 0;
  }
}
