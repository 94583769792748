@mixin font-family($value: regular) {
	@if $value == headlight {
		font-family: "NotoSans-Light", "Arial", "Helvetica", sans-serif;
		font-style: normal;
		font-weight: normal;
	}
	@else if $value == headblack {
		font-family: "NotoSans-Bold", "Arial", "Helvetica", sans-serif;
		font-style: normal;
		font-weight: normal;
	}
	@else if $value == sansbold {
		font-family: "NotoSans-Semibold", "Arial", "Helvetica", sans-serif;
		font-style: normal;
		font-weight: normal;
	}
	@else  {
		font-family: "NotoSans-Regular", "Arial", "Helvetica", sans-serif;
		font-style: normal;
		font-weight: normal;
	}
}

//
// H1 Mixin
//
@mixin headline1() {
  font-size: 9vw;
  line-height: 10vw;
  margin-bottom: 6vw;

  @include breakpoint(min) {
    font-size: $h1fontsizemin + px;
    line-height: $h1lineheightmin + px;
    // font-size: calc(#{$h1fontsizemid}px + (#{$h1fontsizemax} - #{$h1fontsizemid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
    // line-height: calc(#{$h1lineheightmid}px + (#{$h1lineheightmax} - #{$h1lineheightmid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
    margin-bottom: 24px;
  }

  @include breakpoint(mid) {
    font-size: calc(
      #{$h1fontsizemid}px + (#{$h1fontsizemax} - #{$h1fontsizemid}) *
        ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint}))
    );
    line-height: calc(
      #{$h1lineheightmid}px + (#{$h1lineheightmax} - #{$h1lineheightmid}) *
        ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint}))
    );
    margin-bottom: 2vw;
  }

  @include breakpoint(max) {
    font-size: 4vw;
    line-height: 4.5vw;
  }
}

//
// H2 Mixin
//
@mixin headline2() {
  font-size: 6.5vw;
  line-height: 7.5vw;
  margin-bottom: 6vw;

  @include breakpoint(min) {
    font-size: $h2fontsizemin + px;
    line-height: $h2lineheightmin + px;
    // font-size: calc(#{$h2fontsizemid}px + (#{$h2fontsizemax} - #{$h2fontsizemid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
    // line-height: calc(#{$h2lineheightmid}px + (#{$h2lineheightmax} - #{$h2lineheightmid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
    margin-bottom: 24px;
  }

  @include breakpoint(mid) {
    font-size: calc(
      #{$h2fontsizemid}px + (#{$h2fontsizemax} - #{$h2fontsizemid}) *
        ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint}))
    );
    line-height: calc(
      #{$h2lineheightmid}px + (#{$h2lineheightmax} - #{$h2lineheightmid}) *
        ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint}))
    );
    margin-bottom: 2vw;
  }

  @include breakpoint(max) {
    font-size: 3vw;
    line-height: 3.875vw;
  }
}

//
// H3 Mixin
//
@mixin headline3() {
  font-size: 5vw;
  line-height: 6vw;
  margin-bottom: 4vw;

  @include breakpoint(min) {
    font-size: $h3fontsizemin + px;
    line-height: $h3lineheightmin + px;
    // font-size: calc(#{$h3fontsizemid}px + (#{$h3fontsizemax} - #{$h3fontsizemid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
    // line-height: calc(#{$h3lineheightmid}px + (#{$h3lineheightmax} - #{$h3lineheightmid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
    margin-bottom: 24px;
  }

  @include breakpoint(mid) {
    font-size: calc(
      #{$h3fontsizemid}px + (#{$h3fontsizemax} - #{$h3fontsizemid}) *
        ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint}))
    );
    line-height: calc(
      #{$h3lineheightmid}px + (#{$h3lineheightmax} - #{$h3lineheightmid}) *
        ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint}))
    );
    margin-bottom: 1.5vw;
  }

  @include breakpoint(max) {
    font-size: 1.25vw;
    line-height: 2vw;
  }
}
