//*******************************************************************************
// ACCORDION
// https://designsystem.digital.gov/components/accordion/
// SVG to Base64 Background-Image Converter https://codepen.io/jakob-e/pen/doMoML
//*******************************************************************************


.accordionwrapper {

	&:focus,
	&:hover {
		.accordion::before {
			background-color: transparent;
		}
	}
}



.accordion {
	position: relative;
	margin-bottom: 2vw;

	@include breakpoint(mid) {
		margin-left: -1.5vw;
		margin-right: -1.5vw;
		margin-bottom: 1vw;
	}

	@include breakpoint(max) {
		margin-left: -1vw;
		margin-right: -1vw;
		margin-bottom: 0.5vw;
	}

	// 1px Divider Line
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		width: 100%;
		height: 1px;
		background-color: color(coolgray200);
		z-index: -2;
	}

	&:first-child {
		&::before {
			background-color: transparent;
		}
	}


	&:hover,
	&:focus,
	&.is--open {
		cursor: pointer;

		&::before {
			background-color: transparent;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: -1px;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 100%;
			height: calc(100% + 1px);
			border-radius: $border-radius;
			box-shadow: $box-shadow--1;
			background-color: transparent;
			z-index: -1;
		}
	}

	.accordionwrapper:focus-within & {
		&::before {
			background-color: transparent;
		}
	}
}

.accordion.is--open+.accordion {
	&::before {
		background-color: transparent;
	}
}

.accordion--hidden-by-tag {
	display: none;
}



.accordion__button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border: none;
	text-align: left;
	padding: 3vw 5vw;
	background-color: transparent;
	transition: $transition;

	@include breakpoint(mid) {
		padding: 1.5vw;
		background-position: right 2vw center;
	}

	@include breakpoint(max) {
		line-height: 1.5vw;
		padding: 0.75vw 1vw;
	}

	&.focus-visible {
		color: color(red500);
		border-radius: $border-radius;
		box-shadow: $box-shadow--1;
	}

	&:focus {
		outline: none;
	}

	&:hover {
		color: color(red500);
		cursor: pointer;
	}
}



.accordion__title {
	margin-bottom: 0;
	@include font-family(sansregular);
	@include bodytextsize;

	@include breakpoint(mid) {
		margin-bottom: 0;
	}

	.accordion.is--open & {
		@include font-family(headblack);
		// border-bottom: 1px solid color(coolgray200);
	}
}



.accordion__buttontext {
	@include font-family(sansbold);

	.no-msie & {
		display: flex;
		align-items: center;
		flex-grow: 1;
		min-height: 15vw;
		min-height: clamp(60px, 15vw, 60px);
	}
}


.accordion__icon {
	// outline: 1px solid blue;
	flex: 0 0 auto;
	display: block;
	width: 20px;
	height: 20px;
	margin-left: 5vw;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 11c.562 0 1 .406 1 1 0 .25-.094.5-.281.719l-8 8a1.101 1.101 0 01-.719.28c-.25 0-.5-.093-.719-.28l-8-8a1.101 1.101 0 01-.28-.719c0-.25.093-.5.28-.719a1.1 1.1 0 01.72-.28c.25 0 .5.093.718.28L16 18.594l7.281-7.313C23.5 11.094 23.75 11 24 11z" fill="#282D37" fill-rule="nonzero"/></svg>');

	@include breakpoint(mid) {
		margin-left: 1vw;
	}

	@include breakpoint(max) {
		width: 32px;
		height: 32px;
		margin-left: 2vw;
	}

	.accordion__button:focus &,
	.accordion__button:hover & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 11c.562 0 1 .406 1 1 0 .25-.094.5-.281.719l-8 8a1.101 1.101 0 01-.719.28c-.25 0-.5-.093-.719-.28l-8-8a1.101 1.101 0 01-.28-.719c0-.25.093-.5.28-.719a1.1 1.1 0 01.72-.28c.25 0 .5.093.718.28L16 18.594l7.281-7.313C23.5 11.094 23.75 11 24 11z" fill="#ec0016" fill-rule="nonzero"/></svg>');
	}

	.accordion__button[aria-expanded=true] & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 21c.562 0 1-.407 1-1 0-.25-.094-.5-.281-.719l-8-8A1.101 1.101 0 0016 11c-.25 0-.5.094-.719.281l-8 8a1.101 1.101 0 00-.28.719c0 .25.093.5.28.718a1.1 1.1 0 00.72.282c.25 0 .5-.094.718-.282L16 13.406l7.281 7.312c.219.188.469.282.719.282z" fill="#282D37" fill-rule="nonzero"/></svg>');
	}

	.accordion__button[aria-expanded=true]:focus &,
	.accordion__button[aria-expanded=true]:hover & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 21c.562 0 1-.407 1-1 0-.25-.094-.5-.281-.719l-8-8A1.101 1.101 0 0016 11c-.25 0-.5.094-.719.281l-8 8a1.101 1.101 0 00-.28.719c0 .25.093.5.28.718a1.1 1.1 0 00.72.282c.25 0 .5-.094.718-.282L16 13.406l7.281 7.312c.219.188.469.282.719.282z" fill="#ec0016" fill-rule="nonzero"/></svg>');
	}
}



.accordion__collapse {
	overflow: hidden;
	margin-top: 0;
	padding: 0 5vw 3vw 5vw;
	transition: $transition;

	@include breakpoint(mid) {
		padding: 0 1.5vw;
	}

	@include breakpoint(max) {
		padding: 0 1vw;
	}

	.accordion:not(.is--open) & {
		display: none;
	}

	p {
		@include textcompactsize;
	}

	ul,
	ol {
		padding-left: 20px;
		margin-left: 0;

		li {
			@include textcompactsize;
		}

		ul {
			padding-left: 0;
		}
	}

	.list--arrow,
	.list--circle {
		padding-left: 0;
	}

	p:last-child {
		margin-bottom: 0;
	}

	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 3vw;

		@include breakpoint(mid) {
			height: 30px;
		}

		@include breakpoint(max) {
			height: 12px;
		}
	}
}

// Transition classes to handle overflow during transition
// @see https://stackoverflow.com/questions/27904886/can-i-apply-a-css-transition-to-the-overflow-property#answer-41221543
.accordion--no-overflow {
  .accordion__collapse {
    overflow: hidden;
    animation: $transition-time delay-no-overflow;
  }
}

@keyframes delay-no-overflow {
  from { overflow: visible; }
}

.accordion--overflow {
  .accordion__collapse {
    overflow: visible;
    animation: calc($transition-time + 0.5s) delay-overflow;
  }
}

@keyframes delay-overflow {
  from { overflow: hidden; }
}
