@font-face {
  font-family: "NotoSans-Regular";
  font-display: swap;
  src: url("/_Resources/Static/Packages/DB.Site/Fonts/NotoSans-Regular/NotoSans-Regular.woff2")
      format("woff2"),
    url("/_Resources/Static/Packages/DB.Site/Fonts/NotoSans-Regular/NotoSans-Regular.woff")
      format("woff");
}

@font-face {
  font-family: "NotoSans-Light";
  font-display: swap;
  src: url("/_Resources/Static/Packages/DB.Site/Fonts/NotoSans-Light/NotoSans-Light.woff2")
      format("woff2"),
    url("/_Resources/Static/Packages/DB.Site/Fonts/NotoSans-Light/NotoSans-Light.woff")
      format("woff");
}

@font-face {
  font-family: "NotoSans-Semibold";
  font-display: swap;
  src: url("/_Resources/Static/Packages/DB.Site/Fonts/NotoSans-Semibold/NotoSans-Semibold.woff2")
      format("woff2"),
    url("/_Resources/Static/Packages/DB.Site/Fonts/NotoSans-Semibold/NotoSans-Semibold.woff")
      format("woff");
}

@font-face {
  font-family: "NotoSans-Bold";
  font-display: swap;
  src: url("/_Resources/Static/Packages/DB.Site/Fonts/NotoSans-Bold/NotoSans-Bold.woff2")
      format("woff2"),
    url("/_Resources/Static/Packages/DB.Site/Fonts/NotoSans-Bold/NotoSans-Bold.woff")
      format("woff");
}
