//*******************************************************************************
// Related Tiles Component
//*******************************************************************************


.relatedtiles {
	position: relative;
	padding-bottom: 10vw;

	@include breakpoint(mid) {
		display: flex;
		justify-content: center;
		width: 98vw;
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 6vw;
	}

	@include breakpoint(max) {
		width: 90vw;
		max-width: none;
		margin-right: 5vw;
		margin-left: 5vw;
	}

	// Grey Background Area
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 5vw;
		right: 5vw;
		width: 90vw;
		height: calc(100% - 18vw);
		margin-top: 18vw;
		background-color: color(coolgray100);
		border-radius: $border-radius;
		z-index: -1;

		@include breakpoint(mid) {
			left: 0;
			right: 0;
			width: 100%;
			height: calc(100% - 6vw);
			margin-top: 6vw;
		}
	}

	.tile {
		display: block;
		margin: 0 10vw 8vw 10vw;
		padding: 4vw;
		color: color(dark);
		background-color: color(bright);
		border-radius: $border-radius;
		box-shadow: $box-shadow--2;
		transition: all $transition-time $transition-function;

		&:focus {
			box-shadow: $box-shadow--1 !important;
			text-decoration: none;
			color: color(dark);
		}

		&.focus-visible:focus {
			outline: none;
			box-shadow: 0 0 0 3px color(red500) !important;
			text-decoration: none;
			color: color(dark);
		}


		&:hover {
			text-decoration: none;
			color: color(dark);
			box-shadow: $box-shadow--1;
		}

		@include breakpoint(mid) {
			display: flex;
			flex-direction: column;
			flex: 0 1 30vw;
			margin: 0;
			padding: 2vw;
			max-width: 400px;

			&:nth-of-type(2) {
				margin-left: clamp(16px, 2vw, 40px);
				margin-right: clamp(16px, 2vw, 40px);

				.msie & {
					margin-left: 2vw;
					margin-right: 2vw;
				}
			}
		}

		@include breakpoint(max) {
			max-width: 25vw;

			&:nth-of-type(2) {
				margin-left: 2.5vw;
				margin-right: 2.5vw;
			}
		}
	}



	//
	// Tile with image or icon
	//
	.tile.tile--has-image {
		padding: 0;

		.tile__title,
		.tile__footer {
			padding-left: 4vw;
			padding-right: 4vw;

			@include breakpoint(mid) {
				padding-left: 2vw;
				padding-right: 2vw;
			}
		}

		.no-msie & .tile__title {
			@include breakpoint(mid) {
				// flex: 0 1 auto;
				align-items: flex-start;
				flex: 1 1 auto;
			}

			@include breakpoint(max) {
				// flex: 0 1 6vw;
			}
		}

		.tile__footer {
			padding-bottom: 4vw;

			@include breakpoint(mid) {
				padding-bottom: 0;
				margin-bottom: 2vw;
				margin-top: 0;
			}
		}
	}

	.tile__media {
		overflow: hidden;
		position: relative;
		margin-bottom: 4vw;
		display: block;

		aspect-ratio: 4 / 3;

		@include breakpoint(mid) {
			margin-bottom: 2vw;
		}
	}

	.tile__image {
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
	}

	.tile__media svg {
		width: 100%;
		border: 0;
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
	}


	.tile__title {
		transition: all $transition-time $transition-function;

		@include breakpoint(mid) {
			margin-bottom: 2vw;

			.msie & {
				min-height: 110px;
			}

			.no-msie & {
				display: flex;
				align-items: center;
				flex: 0 1 8vw;
			}
		}

		.tile:focus &,
		.tile:hover & {
			color: color(red500);
		}

		.tile:active & {
			color: color(red600);
		}
	}

	.tile__copy {
		flex: 1 1 auto;

		p {
			@include textcompactsize();
		}
	}

	.tile__footer {
		margin-top: 4vw;
		margin-bottom: 0;

		.linkbutton {
			display: inline-block;
			padding-right: 1vw;

			.tile:focus &,
			.tile:hover & {
				color: color(red500);

				&::after {
					background-position: left 12px top 2px;
					@include background-svg('<svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 4-4 4" stroke="#ec0016" stroke-width="1.333" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>');
				}
			}
		}
	}
}
